<template>
  <v-theme-provider>
    <base-section
      id="boards"
      space="40"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <base-section-heading title="Dewan Direksi" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="card in cards"
                    :key="card.name"
                    md="4"
                    xs="12"
                  >
                    <div 
                    data-aos="zoom-out"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    data-aos-anchor-placement="top-center">
                    <v-hover v-slot="{ hover }">
                    <v-card
                    :elevation="hover ? 20 : 0"
                    :class="{ 'on-hover': hover }"
                    outlined>
                      <v-img
                        :src="require(`@/assets/${card.src}`)"
                        class="white--text align-end"
                        height="320px"
                        :gradient="hover ? '' : 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'"
                        :alt="card.name"
                      >
                        <v-card-title class="body-1" v-text="card.name"></v-card-title>
                         <v-card-text v-if="hover" class="caption">
                        {{ card.detail }}
                      </v-card-text>
                      </v-img>
                      <v-card-actions>
                        {{ card.title }}
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                    </div>
                  </v-col>
                  <v-col class="text-center mt-3">
                  <base-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    color="accent"
                    large
                    outlined
                    to="struktur-perusahaan"
                    v-if="$route.name === 'tentang-kami'"
                    class = "text-capitalize"
                  >
                    Struktur Perusahaan
                  </base-btn>
                </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBoards',
    data: () => ({
            posts: [],
            cards: [
              { name: 'Achmad Masfuri', title: 'Direktur Utama', src: 'CEO-Achmad-Masfuri.jpg', detail: 'Lahir di Tegal, 1 Juni 1969. Diangkat sebagai Direktur Utama berdasarkan Keputusan Anggota Dewan Komisioner Otoritas Jasa Keuangan tentang Hasil Penilaian Kemampuan dan Kepatutan Nomor KEP-5/NB.22/2018 Tanggal 13 Feb 2018. Pendidikan terakhir D4 Sekolah Akuntansi Negara Tahun 1991' },
              { name: 'Ihrom Bayu', title: 'Direktur Teknik', src: 'CTO-Ihrom-Bayu.jpg', detail: 'Lahir di Jakarta, 3 Februari 1977. Diangkat sebagai Direktur Teknik berdasarkan Keputusan Anggota Dewan Komisioner Otoritas Jasa Keuangan tentang Hasil Penilaian Kemampuan dan Kepatutan Nomor KEP-7/NB.22/2019 Tanggal 12 Feb 2019. Pendidikan terakhir S1 Sarjana Teknik Universitas Indonesia Tahun 2000 dan bergelar ,ST, AAIK, Dipl. Insurance, AIIS, CRMP dan QIP.' },
              { name: 'Melda Maesarach', title: 'Direktur Kepatuhan', src: 'CRO-Melda- Maesarach.jpg', detail: 'Lahir di Bandung, 9 Mei 1976. Menempuh Pendidikan S1 di Universitas Pasundan Bandung dan S2 Magister Keuangan Syariah di STIE Ahmad Dahlan Jakata Tahun 2014. Aktif sebagai Dosen Prodi Ekonomi Islam Fakultas Ekonomi Bisnis Universitas Muhammadiyah Jakarta sampai dengan sekarang.' },
            ],
        }),
  }
</script>
<style>